// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Icons
// import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoad, faCar, faGasPump } from "@fortawesome/free-solid-svg-icons";

// Style
import styles from "./CenteredBlogCard.module.css";

function CenteredBlogCard({  title, action, vehicleDetails }) {
  const vehicleUrl = vehicleDetails.main.data.attributes.url
  
  return (
    <Card sx={{ width: "100%" }}>
      <a href={action.route} className={styles.carListCard}>
        <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
          <MKBox
            component="img"
            src={`https://admin.kondodycarworld.com/${vehicleUrl}`}
            alt={title}
            borderRadius="lg"
            width="100%"
            position="relative"
            zIndex={1}
            height="250px"
            className={styles.image}
          />
          <MKBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${vehicleDetails.main.data.attributes.url})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKBox>
        <MKBox p={3} mt={-1} textAlign="center">
          <div className={styles.carListCardHeaderDiv}>
            <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular" className={styles.carListCardHeader}>
              {vehicleDetails.vehicle_name}
            </MKTypography>
          </div>
          <MKBox display="flex" sx={{ justifyContent: "space-around" }}>
            <MKBox>
              <FontAwesomeIcon icon={faCar} />
              <br />
              <p className={styles.description}>{vehicleDetails.model}</p>
            </MKBox>
            <MKBox>
              <FontAwesomeIcon icon={faRoad} />
              <br />
              <p className={styles.description}>{vehicleDetails.distance}</p>            
            </MKBox>
            <MKBox>
              {/* <LocalGasStationIcon /> */}
              <FontAwesomeIcon icon={faGasPump} />
              <br />
              <p className={styles.description}>{vehicleDetails.fuel}</p>
            </MKBox>
          </MKBox>
        </MKBox>
      </a>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard;