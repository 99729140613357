import { useEffect, useState} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import VehicleListCard from "./sections/VehicleListCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-vh-list.webp";

// Gifs
import LoadingGif from "assets/gifs/car-loading-gif-16.gif"

// GraphQL
import { useQuery, gql } from '@apollo/client';

// Style
import style from './VehicleList.module.css'
import Pagination from '@mui/material/Pagination';

const GET_VEHICLE_DETAILS = gql`
  query GetVehicleDetails($start: Int!, $limit: Int!) {
    vehicleDetails(pagination: { start: $start, limit: $limit }) {
      meta {
        pagination {
          total
          page
          pageCount
          pageSize
        }
      }
      data {
        id
        attributes {
          vehicle_name
          model
          main {
            data {
              attributes {
                url
              }
            }
          }
          fuel
          distance
          price
        }
      }
    }
  }
`;


function VehicleList() {
  
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of vehicles per page

  const { loading, error, data } = useQuery(GET_VEHICLE_DETAILS, {
    variables: {
      start: (currentPage - 1) * itemsPerPage, // Calculate starting index
      limit: itemsPerPage, // Items per page
    },
  });

  useEffect(() => {
    if (data && data.vehicleDetails) {
      setList(data.vehicleDetails.data);
    }
  }, [data]);

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "default",
        // }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
              className={style.bannerTitle}
            >
              Our Collection
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Dive headfirst into our treasure trove of vehicles!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {error && 
          <Grid className="d-flex justify-content-center align-items-center h-100">
            Error
          </Grid>
        }
        { loading && 
          <Grid className="h-100" lg={12}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={LoadingGif} alt="Loading.." />
            </div>
          </Grid>
        }
        {list && 
          <VehicleListCard data={list}/>
        }
        <MKBox display="flex" justifyContent="center" mt={4}>
          {data?.vehicleDetails.meta.pagination && (
            <Pagination
              count={data.vehicleDetails.meta.pagination.pageCount} // Total pages
              page={currentPage} // Current page
              onChange={handlePageChange} // Page change handler
              size="large"
            />
          )}
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default VehicleList;